import "./style.css";
import logo from "img/LogoNadar.png";

const Home = () => {
  return (
    <>
      <img
        className="logo-home"
        src={logo}
        style={{ margin: "auto", display: "block" }}
      />
    </>
  );
};

export default Home;
