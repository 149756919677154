import axios from "axios";
import { getToken, logout } from "utils/utils";

export const getAsigados = async (filtro: any) => {
  const token = getToken();
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_INTRANET}/api/usuario/GetAsignado/${filtro}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).catch((e) => {
    const { status } = e.response;
    if (status === 401) {
      logout();
    } else {
      throw Error(e.response);
    }
  });
};

export const getAreas = async () => {
  const token = getToken();
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_INTRANET}/api/area/GetAll`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).catch((e) => {
    const { status } = e.response;
    if (status === 401) {
      logout();
    } else {
      throw Error(e.response);
    }
  });
};

export const getCos = async () => {
  const token = getToken();
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_INTRANET}/api/co/GetAll`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).catch((e) => {
    const { status } = e.response;
    if (status === 401) {
      logout();
    } else {
      throw Error(e.response);
    }
  });
};

export const saveCmdb = async (data) => {
  const token = getToken();
  return axios({
    method: "POST",
    data,
    url: `${process.env.REACT_APP_API_INTRANET}/api/cmdb/save`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).catch((e) => {
    const { status } = e.response;
    console.log(e.response);
    if (status === 401) {
      logout();
    } else {
      throw Error(e.response);
    }
  });
};

export const getCmdbById = async (id) => {
  const token = getToken();
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_INTRANET}/api/cmdb/GetById/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).catch((e) => {
    const { status } = e.response;
    if (status === 401) {
      logout();
    } else {
      throw Error(e.response);
    }
  });
};

export const getAllCmdb = async () => {
  const token = getToken();
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_INTRANET}/api/cmdb/GetAll`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).catch((e) => {
    const { status } = e.response;
    if (status === 401) {
      logout();
    } else {
      throw Error(e.response);
    }
  });
};
