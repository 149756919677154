import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Table, Tag } from "antd";
import { PlusSquareOutlined } from "@ant-design/icons";
import { getAllCmdb } from "services/hojas-vida/hv-serv";

const columns = [
  {
    title: "Asignado",
    dataIndex: "nombreAsignado",
  },
  {
    title: "Area",
    dataIndex: "descripcionArea",
  },
  {
    title: "CO",
    dataIndex: "descripcionCo",
  },
  {
    title: "Tipo",
    dataIndex: "tipo",
  },
  {
    title: "Nombre",
    dataIndex: "nombre",
  },
  {
    title: "Marca",
    dataIndex: "marca",
  },
  {
    title: "Procesador",
    dataIndex: "procesador",
  },
  {
    title: "Almacenamiento",
    dataIndex: "almacenamiento",
  },
  {
    title: "Estado",
    dataIndex: "estado",
    render: (_, { estado }) =>
      estado === "ACTIVO" ? (
        <Tag color="green" key={estado}>
          {estado}
        </Tag>
      ) : (
        <Tag color="cyan" key={estado}>
          {estado}
        </Tag>
      ),
  },
];

const ListHV = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getAllCmdb().then((d: any) => {
      const data = d.data;
      setData([...data]);
    });
  }, []);

  return (
    <>
      <div style={{ float: "right", marginBottom: 10, marginTop: 10 }}>
        <Button onClick={() => navigate("/sistemas/hv/guardar/-1")}>
          <PlusSquareOutlined />
        </Button>
      </div>

      <Table
        rowKey={"id"}
        columns={columns}
        dataSource={data}
        size="small"
        scroll={{
          x: "100%",
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/sistemas/hv/guardar/${record.id}`);
            },
          };
        }}
      />
    </>
  );
};

export default ListHV;
