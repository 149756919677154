import axios from "axios";
import { getToken, logout } from "utils/utils";

export const createProducts = async (brand: any) => {
  const token = getToken();
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_INTRANET}/api/TaskAWS/CreateProducts/${brand}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).catch((e) => {
    const { status } = e.response;
    if (status === 401) {
      logout();
    } else {
      throw Error(e.response);
    }
  });
};