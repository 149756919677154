import "./style.css";
import logo from "img/LogoNadar.png";
import { useEffect, useState } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { sendServerLogin, toast } from "utils/utils";
import useToken from "hooks/use-token";

const Copyright = (props: any) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link
        color="inherit"
        target={"_blank"}
        href="https://www.speedocolombia.com/"
      >
        NADAR S.A
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const theme = createTheme();

const Login = () => {
  const { session, saveSession } = useToken();
  const [disableBtn, setDisableBtn] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validPwd, setValidPwd] = useState(false);
  const [dataUpd] = useState({
    userName: null,
    cambioClave: null,
  });

  const activesssion = () => {
    if (session) {
      window.location.href = "/home";
    }
  };

  useEffect(() => {
    activesssion();
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const dataString = JSON.stringify(Object.fromEntries(data));
    const dataJson = JSON.parse(dataString);
    console.log(dataJson);
    sendFrmLogin(dataJson);
  };

  const handleValidEmail = (e: any) => {
    if (e.target.value === "") {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  };

  const handleValidPwd = (e: any) => {
    if (e.target.value === "") {
      setValidPwd(true);
    } else {
      setValidPwd(false);
    }
  };

  const sendFrmLogin = async (quey: any) => {
    try {
      setDisableBtn(true);
      if (!quey.userName || !quey.clave) {
        toast(
          "info",
          process.env.APP_NAME,
          "Todos los valores son obligatorios"
        );
        setDisableBtn(false);
        setValidEmail(true);
        setValidPwd(true);
        return;
      }

      const data = await sendServerLogin({
        userName: quey.userName,
        password: quey.clave,
      });

      setDisableBtn(false);
      saveSession(data);
      window.location.href = "/home";
    } catch (e: any) {
      if (e.msg === "ChanguePass") {
        dataUpd.userName = quey.userName;
        setDisableBtn(false);
      } else {
        toast(
          "info",
          process.env.APP_NAME,
          "No se puede iniciar, valida tus datos"
        );
        setDisableBtn(false);
      }
    }
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            paddingTop={5}
          >
            <img style={{ height: 120 }} src={logo} />
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                onKeyUp={handleValidEmail}
                error={validEmail}
                margin="normal"
                required
                fullWidth
                id="userName"
                label="Usuario"
                name="userName"
              />
              <TextField
                onKeyUp={handleValidPwd}
                error={validPwd}
                margin="normal"
                required
                fullWidth
                name="clave"
                label="Clave"
                type="password"
                id="clave"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={disableBtn}
              >
                INICIAR
              </Button>
              <Grid container>
                <Grid item xs></Grid>
                <Grid item></Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </>
  );
};

export default Login;
