import { Modal, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import request from "axios";

const logout = async () => {
  localStorage.clear();
  window.location.href = "/";
};

/**
 * Recupera la sesion
 * @returns
 */
const getSesion = () => {
  const sesionString = localStorage.getItem("session");
  const userToken = JSON.parse(sesionString);
  return userToken;
};

/**
 *
 * @returns
 */
const getToken = () => {
  const sesionString = localStorage.getItem("session");
  if (sesionString) {
    const userToken = JSON.parse(sesionString);
    return userToken?.token;
  } else {
    return "";
  }
};

/**
 * Cambia color
 * @param {*} id
 * @param {*} color
 */
const changeColor = (id, color) => {
  const element = document.querySelector(id);
  element.style.backgroundColor = color;
};

/**
 * mensaje tipo toast
 * @param {*} type
 * @param {*} message
 * @param {*} description
 */
const toast = (type, message, description) => {
  notification[type]({ message, description });
};

/**
 * Mensaje tipo confirm
 * @param {*} title
 * @param {*} content
 * @param {*} callcack
 */
const confirm = (title, content, callcack) => {
  Modal.confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    content,
    okText: "Si",
    cancelText: "No",
    onOk() {
      callcack();
    },
  });
};

/**
 * Solo para hacer login
 * @param {*} data
 * @returns
 */
const sendServerLogin = async (data) => {
  try {
    const result = await request({
      method: "POST",
      url: `${process.env.REACT_APP_API_INTRANET}/api/Login/Authenticate`,
      data,
      headers: {
        "content-type": "application/json",
      },
    });
    return result?.data;
  } catch (e) {
    throw e.response.data;
  }
};

const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = (c === "x") ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const cleanAcentos = (text) => {
  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .replace(/ /g, "_");
};

const back = () => {
  window.history.back();
};

const isNumeric = (value) => {
  return /^-?\d+$/.test(value);
}

const firstUpper = (value: string): string => {
  if (value.split('-').length > 1) {
    const data = value.replaceAll('-', ' ');
    return data.charAt(0).toUpperCase() + data.slice(1);
  } else {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}

export {
  sendServerLogin,
  changeColor,
  confirm,
  toast,
  getSesion,
  logout,
  uuidv4,
  cleanAcentos,
  back,
  getToken,
  isNumeric,
  firstUpper
};
