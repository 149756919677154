import { useState } from "react";
import * as XLSX from "xlsx";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload, Card, Row, Col, Button, Tooltip } from "antd";
import { loadProducts } from "services/photoslurp/photoslurp-serv";
import type { UploadFile, UploadProps, RcFile } from "antd/es/upload/interface";
import Tutorial from "./tutorial";
import {
  IProducto,
  IImagen,
  IPhotoslurpRequest,
} from "models/photoslurp/productos-model";

const { Dragger } = Upload;

const LoadFile = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const props: UploadProps = {
    name: "file",
    accept: ".xlsx,.xls",
    multiple: true,
    maxCount: 2,
    beforeUpload: (file) => {
      const isExcel = file.type.includes("excel");
      if (fileList.length > 2) {
        message.error(
          `Solo se permite cargar el excel de productos e imagenes.`
        );
      }
      if (!isExcel) {
        message.error(`Formato del archivo invalido, debe ser un Excel.`);
        return isExcel || Upload.LIST_IGNORE;
      } else if (isExcel && fileList.length < 2) {
        setFileList([...fileList, file]);
        return false;
      }
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
  };

  const handleUpload = () => {
    // Globals
    setUploading(true);
    const productos: Array<IProducto> = [];
    const imagenes: Array<IImagen> = [];

    fileList.forEach((file, i) => {
      const reader = new FileReader();
      reader.onloadend = function (event) {
        // Write Excel
        const arrayBuffer = reader.result;
        const options: any = { type: "array" };
        const workbook = XLSX.read(arrayBuffer, options);
        const sheetName: any = workbook.SheetNames;
        const sheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(sheet);

        // Excel to JSON
        const [fist] = data;
        if (
          fist["_SKUReferenceCode"] &&
          fist["_SkuId (Not changeable)"] &&
          fist["_CaptionLink (Not changeable)"]
        ) {
          data.forEach((d) => {
            const reference = `${d["_SKUReferenceCode"].split("-")[0]}-${
              d["_SKUReferenceCode"].split("-")[1]
            }`;
            const producto: IProducto = {
              reference: reference.trim(),
              skuId: d["_SkuId (Not changeable)"],
              productName: d["_ProductName (Required)"].trim(),
              url: d["_CaptionLink (Not changeable)"].trim(),
            };
            productos.push(producto);
          });
        } else if (fist["_SkuId"] && fist["Image URL"]) {
          data.forEach((d) => {
            const imagen: IImagen = {
              skuId: d["_SkuId"],
              url: d["Image URL"],
            };
            imagenes.push(imagen);
          });
        }

        // Validate Two file
        if (i === 1) {
          if (!productos.length) {
            message.error("No ha cargado el Excel de productos.");
            return;
          }

          if (!imagenes.length) {
            message.error("No ha cargado el Excel de imagenes.");
            return;
          }

          // Send JSON
          const dataFull: IPhotoslurpRequest = {
            productos,
            imagenes,
          };

          loadProducts(dataFull)
            .then(() => {
              setFileList([]);
              message.success("upload successfully.");
            })
            .catch(() => {
              message.error("upload failed.");
            })
            .finally(() => {
              setUploading(false);
            });
        }
      };
      reader.readAsArrayBuffer(file as RcFile);
    });
  };

  return (
    <>
      <Card
        bordered={true}
        title={
          <Tooltip title="Click para ver el tutorial">
            <span onClick={() => setOpen(true)}>Cargar productos Photoslurp</span>
          </Tooltip>
        }
        style={{ marginTop: 10 }}
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} style={{ marginBottom: 10 }}>
            <Button
              type="primary"
              onClick={handleUpload}
              disabled={fileList.length < 2}
              loading={uploading}
              style={{ marginTop: 16 }}
            >
              {uploading ? "Cargando" : "Cargar archivos"}
            </Button>
          </Col>
        </Row>
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click para cargar los archivos</p>
          <p className="ant-upload-hint">
            Se debe cargar el excel de imagenes y productos que salen de vtex
          </p>
        </Dragger>
      </Card>
      <Tutorial open={open} setOpen={setOpen} />
    </>
  );
};

export default LoadFile;
