import { Modal } from "antd";
import ReactPlayer from 'react-player';

interface PropsModals {
    open: boolean
    setOpen: (flag: boolean) => void
}

const Tutorial = ({ open, setOpen }: PropsModals) => {
  return (
    <Modal
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      width={1000}
      footer={false}
    >
      <ReactPlayer width='100%' controls url={`${process.env.REACT_APP_API_VIDEO}/comercial/adroll.mp4`} />
    </Modal>
  );
};

export default Tutorial;
