import logo from "img/LogoNadarSolo.png";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useToken from "hooks/use-token";
import { IMenu } from "models/shared/session-model";
import { validSession } from 'services/shared/shared-serv';
import {
  Layout,
  Menu,
  Breadcrumb,
  Row,
  Col,
  Tooltip,
  Avatar,
  Drawer,
  Button,
} from "antd";
import {
  logout,
  confirm,
  uuidv4,
  isNumeric,
  firstUpper,
} from "utils/utils";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ApiOutlined,
  WifiOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";

const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;

const Shared = ({ component: Component }: any) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [breadcrumb, setBreadcrumb] = useState<Array<any>>([]);
  const [isNetwork, setIsNetwork] = useState<string>("#04AA6D");
  const [dataMenu, setDataMenu] = useState<Array<IMenu>>([]);
  const { session } = useToken();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log(session);
    loadMenu();
    networkInterval();
    validSession();
  }, []);

  useEffect(() => {
    getBreadcrumb();
  }, [location.pathname]);

  const getBreadcrumb = () => {
    const urls = location.pathname.split("/");
    urls.shift();
    setBreadcrumb([...urls]);
  };

  const loadMenu = () => {
    setDataMenu([...session.menu]);
  };

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const onCollapseMenu = (flag: boolean) => {
    if (flag) {
      setCollapsed(!collapsed);
    }
  };

  const showUserName = () => {
    if (session.name) {
      return session.name;
    } else {
      return "System";
    }
  };

  const handleLogout = () => {
    confirm("Salir", "¿Desea salir de la aplicación?", logout);
  };

  const networkInterval = () => {
    setInterval(() => {
      fetch("https://www.google.com/", {
        mode: "no-cors",
      })
        .then(() => {
          setIsNetwork("#04AA6D");
        })
        .catch(() => {
          console.log("INTERNET CONNECTIVITY ISSUE");
          setIsNetwork("red");
        });
    }, 5000);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Drawer
        title={
          <Link to="/home" className="brand-link">
            <img
              className="logo"
              alt="Creaciones Nadar"
              src={logo}
              onClick={onCollapse}
            />
          </Link>
        }
        placement="left"
        onClose={onCollapse}
        visible={collapsed}
        closable={false}
        width={280}
      >
        <Menu theme="dark" mode="inline">
          {dataMenu.map((m: any) =>
            m.options.length > 0 ? (
              <SubMenu key={uuidv4()} title={m.nombreMenu}>
                {m.options.map((o: any) =>
                  o.items.length > 0 ? (
                    <SubMenu key={uuidv4()} title={o.nombreOption}>
                      {o.items.map((i: any) => (
                        <Menu.Item key={uuidv4()}>
                          <Link
                            to={i.pathRouteItem ? i.pathRouteItem : "/sinpath"}
                            className="brand-link"
                            onClick={() => onCollapseMenu(true)}
                          >
                            {i.nombreItem}
                          </Link>
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  ) : (
                    <Menu.Item
                      key={uuidv4()}
                      onClick={() => onCollapseMenu(true)}
                    >
                      <Link
                        to={o.pathRouteOption ? o.pathRouteOption : "/sinpath"}
                        className="brand-link"
                      >
                        {o.nombreOption}
                      </Link>
                    </Menu.Item>
                  )
                )}
              </SubMenu>
            ) : (
              <Menu.Item key={uuidv4()} onClick={() => onCollapseMenu(true)}>
                <Link
                  to={m.pathRouteMenu ? m.pathRouteMenu : "/sinpath"}
                  className="brand-link"
                >
                  {m.nombreMenu}
                </Link>
              </Menu.Item>
            )
          )}
        </Menu>
      </Drawer>

      <Layout className="site-layout">
        <Header
          className="site-layout-background myHeader"
          style={{ padding: 0, position: "fixed", zIndex: 1, width: "100%" }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger btnTrigger",
              onClick: onCollapse,
            }
          )}

          <div style={{ float: "right", marginRight: "15px" }}>
            <WifiOutlined
              style={{
                verticalAlign: "middle",
                marginRight: 20,
                fontSize: 23,
                color: isNetwork,
              }}
            />

            <Tooltip title={showUserName}>
              <Avatar
                style={{
                  backgroundColor: "#f56a00",
                  verticalAlign: "middle",
                  marginRight: 20,
                }}
                size="default"
                gap={1}
              >
                {session.name ? session.name.slice(0, 1)?.toUpperCase() : "sys"}
              </Avatar>
            </Tooltip>

            <Tooltip placement="topLeft" title="Salir">
              <ApiOutlined
                style={{
                  cursor: "pointer",
                  verticalAlign: "middle",
                  marginRight: 10,
                  fontSize: 18,
                }}
                onClick={handleLogout}
              />
            </Tooltip>
          </div>
        </Header>

        <Content style={{ margin: "0 16px" }} className="myContent">
          <Row>
            <Col span={24}>
              <Breadcrumb style={{ marginTop: 70 }}>
                {breadcrumb.map((d, i) =>
                  !isNumeric(d) ? (
                    <Breadcrumb.Item key={i}>{firstUpper(d)}</Breadcrumb.Item>
                  ) : undefined
                )}
              </Breadcrumb>
              <Button onClick={handleBack} style={{ marginTop: 10 }}>
                <ArrowLeftOutlined />
              </Button>
              <Component />
            </Col>
          </Row>
        </Content>

        <Footer style={{ textAlign: "center" }}>
          Copyright NADAR S.A - APP 7.1.1
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Shared;
