import "./styles.css";
import { useState } from "react";
import { Row, Col, Button, Card, Divider } from "antd";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { createProducts } from "services/aws/task-serv";
import { toast } from "utils/utils";

const TaskAWS = () => {
  const [marca, setMarca] = useState("Speedo");
  const [flagCreateProducts, setFlagCreateProducts] = useState(false);

  const sendProducts = () => {
    setFlagCreateProducts(true);
    createProducts(marca)
      .then((res) => {
        console.log(res);
        toast("success", process.env.APP_NAME, "Tarea ejecuta correctamente.");
        setFlagCreateProducts(false);
      })
      .catch((e) => {
        console.log(e);
        toast("info", process.env.APP_NAME, "No se pudo ejecutar la tarea.");
        setFlagCreateProducts(false);
      });
  };

  const handleChange = (e) => {
    setMarca(e.target.value);
  };

  return (
    <>
      <Card title="Tareas AWS" style={{ marginTop: 10 }}>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24}>
            <FormControl>
              <InputLabel>Marca</InputLabel>
              <Select value={marca} label="Marca" onChange={handleChange}>
                <MenuItem value="Speedo">Speedo</MenuItem>
                <MenuItem value="Oneill">Oneill</MenuItem>
              </Select>
            </FormControl>
          </Col>

          <Divider />

          <Col xs={24} sm={6} md={6} className="col-task-aws">
            <Button
              type="primary"
              loading={flagCreateProducts}
              style={{ color: "white", width: "100%" }}
              onClick={() => sendProducts()}
            >
              CREAR-PRODUCTOS
            </Button>
          </Col>

          <Col xs={24} sm={6} md={6} className="col-task-aws">
            <Button type="primary" style={{ color: "white", width: "100%" }}>
              PROXIMAMENTE
            </Button>
          </Col>

          <Col xs={24} sm={6} md={6} className="col-task-aws">
            <Button type="primary" style={{ color: "white", width: "100%" }}>
              PROXIMAMENTE
            </Button>
          </Col>

          <Col xs={24} sm={6} md={6} className="col-task-aws">
            <Button type="primary" style={{ color: "white", width: "100%" }}>
              PROXIMAMENTE
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default TaskAWS;
