import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "utils/utils";
import {
  Card,
  Form,
  Row,
  Col,
  Select,
  Input,
  Button,
  Collapse,
  Space,
  Typography,
  InputNumber,
} from "antd";
import {
  SaveOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import {
  getAsigados,
  getAreas,
  getCos,
  saveCmdb,
  getCmdbById,
} from "services/hojas-vida/hv-serv";

const { Panel } = Collapse;
const { Option } = Select;
const { Text } = Typography;
const { TextArea } = Input;

const EditHV = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const [asignados, setAsignados] = useState<Array<any>>([]);
  const [areas, setAreas] = useState<Array<any>>([]);
  const [cos, setCos] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Get basic data
    getAreas().then((d: any) => {
      setAreas([...d.data]);
    });
    getCos().then((d: any) => {
      setCos([...d.data]);
    });

    // Valid id
    const id = location.pathname.split("/").pop();
    if (id !== "-1") {
      getCmdbById(id).then((d: any) => {
        const data = d.data;
        setAsignados([{ id: data.idAsignado, nombres: data.nombreAsignado }]);
        console.log(data);
        form.setFieldsValue({
          ...data,
          otrosDispositivos: data.otrosDispositivos
            ? JSON.parse(data.otrosDispositivos)
            : undefined,
          software: data.software ? JSON.parse(data.software) : undefined,
        });
      });
    }
  }, []);

  const handleSubmit = async (item: any) => {
    try {
      item.otrosDispositivos = JSON.stringify(item.otrosDispositivos);
      item.software = JSON.stringify(item.software);
      setLoading(true);
      console.log(item);
      saveCmdb(item).then((d: any) => {
        const id = location.pathname.split("/").pop();
        setLoading(false);
        if (id === "-1") {
          form.resetFields();
        }
        setAsignados([]);
        toast("success", "Intranet", "Guardado correctamente");
      })
      .catch(e => {
        toast("success", "Intranet", "Guardado correctamente");
      });
    } catch (e: any) {
      toast("info", "Intranet", `No se puede guardar - ${e.message}`);
    }
  };

  const handleChangeAsignado = (value) => {
    form.setFieldsValue({ idAsignado: value });
  };

  const handleSearchAsignados = (value) => {
    if (value) {
      getAsigados(value.trim()).then((d: any) => {
        setAsignados([...d.data]);
      });
    } else {
      setAsignados([]);
    }
  };

  const handleCleanEscAsignados = (code, object) => {
    if (code === "Escape") {
      setAsignados([]);
      form.setFieldsValue(object);
      console.log(code);
    }
  };

  return (
    <Card bordered={true} title="Hojas de vida CMDB" style={{ marginTop: 10 }}>
      <Form onFinish={handleSubmit} form={form} autoComplete="off">
        <Row gutter={24}>
          {/* id */}
          <Form.Item name="id" hidden={true}>
            <Input />
          </Form.Item>
          <Col xs={24} sm={24} md={24}>
            <Button
              onClick={form.submit}
              type="primary"
              loading={loading}
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              Guardar
              <SaveOutlined />
            </Button>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <Form.Item
              name="idAsignado"
              label="Asignado a"
              labelCol={{ span: 24 }}
              rules={[{ required: false, message: "¡Valor obligatorio!" }]}
            >
              <Select
                optionFilterProp="children"
                placeholder="Asignado a"
                showSearch
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearchAsignados}
                onChange={handleChangeAsignado}
                notFoundContent={null}
                allowClear
                onClear={() => setAsignados([])}
                onInputKeyDown={(e) =>
                  handleCleanEscAsignados(e.code, { idAsignado: null })
                }
              >
                {asignados.map((d) => (
                  <Option key={d.id} value={d.id}>
                    {d.nombres}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={6}>
            <Form.Item
              name="idArea"
              label="Área"
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: "¡Valor obligatorio!" }]}
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                placeholder="Área"
                onInputKeyDown={(e) =>
                  handleCleanEscAsignados(e.code, { idArea: null })
                }
              >
                {areas.map((d) => (
                  <Option key={d.id} value={d.id}>
                    {d.descripcion}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={6}>
            <Form.Item
              name="idCO"
              label="CO"
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: "¡Valor obligatorio!" }]}
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                placeholder="CO"
                onInputKeyDown={(e) =>
                  handleCleanEscAsignados(e.code, { idCO: null })
                }
              >
                {cos.map((d) => (
                  <Option key={d.id} value={d.id}>
                    {d.id} - {d.descripcion}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={6}>
            <Form.Item
              name="tipo"
              label="Tipo"
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: "¡Valor obligatorio!" }]}
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                placeholder="Tipo"
                onInputKeyDown={(e) =>
                  handleCleanEscAsignados(e.code, { Tipo: null })
                }
              >
                <Option value="PC">PC</Option>
                <Option value="PORTATIL">PORTATIL</Option>
                <Option value="CELULAR">CELULAR</Option>
                <Option value="SERVIDOR">SERVIDOR</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={6} md={6}>
            <Form.Item
              label={"Nombre"}
              labelCol={{ span: 24 }}
              name="nombre"
              rules={[{ required: false, message: "¡Valor obligatorio!" }]}
            >
              <Input placeholder="Nombre" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={6} md={6}>
            <Form.Item
              label={"Marca"}
              labelCol={{ span: 24 }}
              name="marca"
              rules={[{ required: false, message: "¡Valor obligatorio!" }]}
            >
              <Input placeholder="Marca" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={6} md={6}>
            <Form.Item
              label={"Procesador"}
              labelCol={{ span: 24 }}
              name="procesador"
              rules={[{ required: false, message: "¡Valor obligatorio!" }]}
            >
              <Input placeholder="Procesador" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={6} md={6}>
            <Form.Item
              label={"Ram"}
              labelCol={{ span: 24 }}
              name="ram"
              rules={[{ required: false, message: "¡Valor obligatorio!" }]}
            >
              <Input placeholder="Ram" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={6} md={6}>
            <Form.Item
              label={"Almacenamiento"}
              labelCol={{ span: 24 }}
              name="almacenamiento"
              rules={[{ required: false, message: "¡Valor obligatorio!" }]}
            >
              <Input placeholder="Almacenamiento" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={6} md={6}>
            <Form.Item
              label={"Valor estimado"}
              labelCol={{ span: 24 }}
              name="valorEstimado"
              rules={[{ required: false, message: "¡Valor obligatorio!" }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                formatter={(value: any) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={6}>
            <Form.Item
              name="estado"
              label="Estado"
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: "¡Valor obligatorio!" }]}
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                placeholder="Estado"
                onInputKeyDown={(e) =>
                  handleCleanEscAsignados(e.code, { Estado: null })
                }
              >
                <Option value="ACTIVO">ACTIVO</Option>
                <Option value="INACTIVO">INACTIVO</Option>
                <Option value="EN GARANTIA">EN GARANTIA</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label={"Observaciones"}
              labelCol={{ span: 24 }}
              name="observaciones"
              rules={[{ required: false, message: "¡Valor obligatorio!" }]}
            >
              <TextArea
                showCount
                maxLength={500}
                style={{
                  height: 90,
                  resize: "none",
                }}
                placeholder="Observaciones"
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24}>
            <Collapse>
              <Panel
                header={
                  <Text style={{ fontWeight: "bold" }} mark>
                    SOFTWARE
                  </Text>
                }
                key="1"
              >
                <Form.List name="software">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: "flex", marginBottom: 8 }}
                          align="baseline"
                        >
                          <Row gutter={24}>
                            <Col xs={24} sm={6} md={6}>
                              <Form.Item
                                {...restField}
                                name={[name, "marca"]}
                                fieldKey={[fieldKey, "marca"]}
                                rules={[
                                  {
                                    required: false,
                                    message: "¡Valor obligatorio!",
                                  },
                                ]}
                              >
                                <Input placeholder="Marca" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6} md={6}>
                              <Form.Item
                                {...restField}
                                name={[name, "descripcion"]}
                                fieldKey={[fieldKey, "descripcion"]}
                                rules={[
                                  {
                                    required: false,
                                    message: "¡Valor obligatorio!",
                                  },
                                ]}
                              >
                                <Input placeholder="Descripción" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6} md={6}>
                              <Form.Item
                                {...restField}
                                name={[name, "serial"]}
                                fieldKey={[fieldKey, "serial"]}
                                rules={[
                                  {
                                    required: false,
                                    message: "¡Valor obligatorio!",
                                  },
                                ]}
                              >
                                <Input placeholder="Serial" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6} md={6}>
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            </Col>
                          </Row>
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Nuevo
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Panel>

              <Panel
                header={
                  <Text style={{ fontWeight: "bold" }} mark>
                    OTROS DISPOSITIVOS
                  </Text>
                }
                key="2"
              >
                <Form.List name="otrosDispositivos">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: "flex", marginBottom: 8 }}
                          align="baseline"
                        >
                          <Row gutter={24}>
                            <Col xs={24} sm={6} md={6}>
                              <Form.Item
                                {...restField}
                                name={[name, "marca"]}
                                fieldKey={[fieldKey, "marca"]}
                                rules={[
                                  {
                                    required: false,
                                    message: "¡Valor obligatorio!",
                                  },
                                ]}
                              >
                                <Input placeholder="Marca" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6} md={6}>
                              <Form.Item
                                {...restField}
                                name={[name, "descripcion"]}
                                fieldKey={[fieldKey, "descripcion"]}
                                rules={[
                                  {
                                    required: false,
                                    message: "¡Valor obligatorio!",
                                  },
                                ]}
                              >
                                <Input placeholder="Descripción" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6} md={6}>
                              <Form.Item
                                {...restField}
                                name={[name, "serial"]}
                                fieldKey={[fieldKey, "serial"]}
                                rules={[
                                  {
                                    required: false,
                                    message: "¡Valor obligatorio!",
                                  },
                                ]}
                              >
                                <Input placeholder="Serial" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6} md={6}>
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            </Col>
                          </Row>
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Nuevo
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default EditHV;
