import axios from "axios";
import { getToken, logout } from "utils/utils";
import { IAdrollRequest } from 'models/adroll/productos-model';

export const loadProducts = async (data: IAdrollRequest) => {
  console.log(data);
  const token = getToken();
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_INTRANET}/api/Adroll/LoadProducts`,
    data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).catch((e) => {
    console.log(e.response?.data?.message);
    const { status } = e.response;
    if (status === 401) {
      logout();
    } else {
      throw Error(e.response);
    }
  });
};