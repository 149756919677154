import './App.css';
import 'antd/dist/antd.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import useToken from './hooks/use-token';
import PrivateRoute from './routes/private-route';
import {
  Login,
  NotFound,
  Shared,
  Home,
  TaskAws,
  EditHV,
  ListHV,
  LoadFileRoll,
  LoadFilePS
} from './components/index';

const App = () => {
  const { session } = useToken();
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route element={<PrivateRoute isLogged={session} />} >

          <Route path='/home' element={<Shared component={Home} />} />
          
          <Route path='/sistemas/hv/guardar/:id' element={<Shared component={EditHV} />} />
          <Route path='/sistemas/hv/listar' element={<Shared component={ListHV} />} />

          <Route path='/comercial/adroll/cargar-productos' element={<Shared component={LoadFileRoll} />} />
          <Route path='/comercial/photoslurp/cargar-productos' element={<Shared component={LoadFilePS} />} />
          <Route path='/comercial/task/aws' element={<Shared component={TaskAws} />} />

          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  )
}

export default App;