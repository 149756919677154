import { useState } from "react";
import { ISession } from 'models/shared/session-model';

const useToken = () => {
  const getSession = (): ISession => {
    const sessionString = localStorage.getItem("session");
    const sessionJson: ISession = JSON.parse(sessionString);
    return sessionJson;
  };

  const [session, setSession] = useState<ISession>(getSession());

  const saveSession = (sessionJson: any) => {
    localStorage.setItem("session", JSON.stringify(sessionJson));
    setSession(sessionJson);
  };

  return {
    session,
    saveSession,
  };
};

export default useToken;
