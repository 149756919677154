import axios from "axios";
import { getToken, logout } from "utils/utils";

export const validSession = () => {
  const token = getToken();
  axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_INTRANET}/WeatherForecast/Session`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).catch((e) => {
    const { status } = e.response;
    console.log(status);
    if (status === 401) {
      logout();
    } else {
      throw Error(e.response);
    }
  });
};